import Vue from 'vue';
import VueRouter from 'vue-router';
import { Auth, Cache } from 'aws-amplify';
import store from '@/store';
import { AUTH_EXPIRATION_DAYS, USER_TYPES, MANUAL_KINDS } from '@/assets/js/const';
import { addLog } from '@/assets/js/logger';
import { messageKeys } from '@/assets/js/logger/logMessages';

// モジュール名
const MODULE_NAME = 'router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Root',
  },
  // ログイン
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  // ホーム
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home/Home.vue'),
    meta: {
      userType: [
        USER_TYPES.UNIONIST,
        USER_TYPES.ADMIN,
        USER_TYPES.UNION,
        USER_TYPES.SALES,
        USER_TYPES.MAIL_SUBCONTRACTOR,
      ],
    },
  },
  // 請求書/利用明細一覧
  {
    path: '/invoice_list',
    name: 'InvoiceList',
    component: () => import('../views/InvoiceList.vue'),
    meta: {
      userType: [USER_TYPES.UNIONIST],
    },
  },
  // 請求書/利用明細一覧（管理）
  {
    path: '/invoice_list_manage',
    name: 'InvoiceListManage',
    component: () => import('../views/InvoiceListManage.vue'),
    meta: {
      userType: [USER_TYPES.ADMIN, USER_TYPES.UNION, USER_TYPES.SALES],
    },
  },
  // リンク集
  {
    path: '/brsupport_link',
    name: 'BrsupportLink',
    beforeEnter() {
      store.commit('$_setLoading', false);
      window.open('https://www.br-support.jp/link/', '_blank');
    },
    meta: {
      userType: [
        USER_TYPES.UNIONIST,
        USER_TYPES.ADMIN,
        USER_TYPES.UNION,
        USER_TYPES.SALES,
        USER_TYPES.MAIL_SUBCONTRACTOR,
      ],
    },
  },
  // 各種届出様式
  {
    path: '/brsupport_format',
    name: 'BrsupportFormat',
    beforeEnter() {
      store.commit('$_setLoading', false);
      window.open('https://www.br-support.jp/form/', '_blank');
    },
    meta: {
      userType: [
        USER_TYPES.UNIONIST,
        USER_TYPES.ADMIN,
        USER_TYPES.UNION,
        USER_TYPES.SALES,
        USER_TYPES.MAIL_SUBCONTRACTOR,
      ],
    },
  },
  // ユーザー管理
  {
    path: '/user/search',
    name: 'UserSearch',
    component: () => import('../views/user/UserSearch.vue'),
    meta: {
      userType: [USER_TYPES.ADMIN, USER_TYPES.UNION],
    },
  },
  // ユーザー登録
  {
    path: '/user/create',
    name: 'UserCreate',
    component: () => import('../views/user/UserEdit.vue'),
    meta: {
      userType: [USER_TYPES.ADMIN, USER_TYPES.UNION],
    },
  },
  // ユーザー編集
  {
    path: '/user/edit/:userNo',
    name: 'UserEdit',
    component: () => import('../views/user/UserEdit.vue'),
    meta: {
      userType: [USER_TYPES.ADMIN, USER_TYPES.UNION],
    },
  },
  // 組合員情報
  {
    path: '/unionist',
    name: 'Unionist',
    component: () => import('../views/unionist/Unionist.vue'),
    meta: {
      userType: [USER_TYPES.UNIONIST],
    },
  },
  // 組合員管理
  {
    path: '/unionist/search',
    name: 'UnionistSearch',
    component: () => import('../views/unionist/UnionistSearch.vue'),
    meta: {
//koiwa      userType: [USER_TYPES.ADMIN, USER_TYPES.UNION],
      userType: [USER_TYPES.ADMIN, USER_TYPES.UNION, USER_TYPES.SALES],
    },
  },
  // 組合員登録
  {
    path: '/unionist/create',
    name: 'UnionistCreate',
    component: () => import('../views/unionist/UnionistEdit.vue'),
    meta: {
      userType: [USER_TYPES.ADMIN, USER_TYPES.UNION],
    },
  },
  // 組合員編集
  {
    path: '/unionist/edit/:unionistNo',
    name: 'UnionistEdit',
    component: () => import('../views/unionist/UnionistEdit.vue'),
    meta: {
      userType: [USER_TYPES.ADMIN, USER_TYPES.UNION],
    },
  },
  // 請求書/利用明細アップロード
  {
    path: '/invoice_upload',
    name: 'InvoiceUpload',
    component: () => import('../views/InvoiceUpload.vue'),
    meta: {
      userType: [USER_TYPES.ADMIN, USER_TYPES.UNION],
    },
  },
  // コンテンツ管理
  {
    path: '/contents',
    name: 'Contents',
    component: () => import('../views/contents/Contents.vue'),
    meta: {
      userType: [USER_TYPES.ADMIN, USER_TYPES.UNION],
    },
  },
  // 業務委託手数料アップロード
  {
    path: '/commission_list_upload',
    name: 'CommissionListUpload',
    component: () => import('../views/commission/CommissionListUpload.vue'),
    meta: {
      userType: [USER_TYPES.ADMIN],
    },
  },
  // 業務委託手数料一覧
  {
    path: '/commission_list',
    name: 'CommissionList',
    component: () => import('../views/commission/CommissionList.vue'),
    meta: {
      userType: [USER_TYPES.SALES],
    },
  },
  // 業務委託手数料一覧（管理）
  {
    path: '/commission_list_manage',
    name: 'CommissionListManage',
    component: () => import('../views/commission/CommissionList.vue'),
    meta: {
      userType: [USER_TYPES.ADMIN],
    },
  },
  // 郵送委託先様向けダウンロード
  {
    path: '/mail_outsourcer_download',
    name: 'MailOutsourcerDownload',
    component: () => import('../views/MailOutsourcerDownload.vue'),
    meta: {
      userType: [USER_TYPES.MAIL_SUBCONTRACTOR],
    },
  },
  // 管理者、組合マニュアル
  {
    path: '/manual/admin_union_manual',
    name: 'AdminUnionManual',
    beforeEnter() {
      store.commit('$_setLoading', false);
      window.open(MANUAL_KINDS.ADMIN_UNION_MANUAL, '_blank');
    },
    meta: {
      userType: [USER_TYPES.ADMIN, USER_TYPES.UNION],
    },
  },
  // 組合員マニュアル
  {
    path: '/manual/unionist_manual',
    name: 'UnionistManual',
    beforeEnter() {
      store.commit('$_setLoading', false);
      window.open(MANUAL_KINDS.UNIONIST_MANUAL, '_blank');
    },
    meta: {
      userType: [USER_TYPES.UNIONIST],
    },
  },
  // 担当営業マニュアル
  {
    path: '/manual/sales_manual',
    name: 'SalesManual',
    beforeEnter() {
      store.commit('$_setLoading', false);
      window.open(MANUAL_KINDS.SALES_MANUAL, '_blank');
    },
    meta: {
      userType: [USER_TYPES.SALES],
    },
  },
  // 郵送委託者マニュアル
  {
    path: '/manual/mail_subcontractor_manual',
    name: 'MailSubcontractorManual',
    beforeEnter() {
      store.commit('$_setLoading', false);
      window.open(MANUAL_KINDS.MAIL_SUBCONTRACTOR_MANUAL, '_blank');
    },
    meta: {
      userType: [USER_TYPES.MAIL_SUBCONTRACTOR],
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// ルーティングトリガー時処理
router.beforeEach(async (to, from, next) => {
  const functionName = 'router.beforeEach';

  try {
    if (to.path === from.path) {
      // 同じ画面に遷移する場合は画面遷移を行わない
      return;
    }
    // ローディングエフェクトON
    store.commit('$_setLoading', true);

    // ログイン済ユーザー情報取得
    // 未ログインの場合errorを投げるのでここでAPIコール
    await Auth.currentAuthenticatedUser();
    // Amplify cacheのチェック
    // cacheにユーザー情報が存在した場合、キャッシュの有効期限を更新
    const cachedUser = Cache.getItem('user');
    // キャッシュ更新
    let date = new Date();
    // const.jsで定義した保持日数をセット
    date.setDate(date.getDate() + AUTH_EXPIRATION_DAYS);
    // UNIX時間で有効期限をセット
    Cache.setItem('user', cachedUser, { expires: date.getTime() });

    // Vuexチェック
    // storeにユーザー情報が存在しない場合、Cacheのデータをセット
    if (!store.getters.$_user) store.commit('setStateUser', cachedUser);

    // 存在しており許可されているページであればそのページに遷移、そうでなければTOPページに遷移
    if (to.meta.userType && to.meta.userType.includes(cachedUser.Type)) {
      next();
    } else {
      await addLog({
        messageKey: messageKeys.W2002,
        component: MODULE_NAME,
        func: functionName,
        param: { LoginId: cachedUser.LoginID, path: to.path },
      });
      next({ path: '/home' });
      store.commit('$_setLoading', false);
    }
  } catch {
    // Vuexクリア
    store.commit('setStateUser', null);
    // バージョンキャッシュは残したいのでclearは行わない
    Cache.removeItem('user');
    // 未ログインの場合ログイン画面に遷移
    if (to.path !== '/login') {
      if (from.path === '/login') {
        store.commit('$_setLoading', false);
      } else {
        next({ path: '/login' });
      }
    } else {
      next();
    }
  }
});

export default router;
