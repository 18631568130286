<template>
  <div>
    <!-- サイドメニュー -->
    <!-- ●TODO 幅を広げた際に自動で展開されるが、閾値を上げたい（コンテンツが隠れてしまうので） -->
    <v-navigation-drawer v-if="isShowMenu" app v-model="drawer" clipped left width="350px">
      <v-container>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title grey--text text--darken-2">メインメニュー</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list nav>
          <v-list-item v-for="sideMenu in sideMenuItems" :key="sideMenu.name" :to="sideMenu.link">
            <v-list-item-icon>
              <v-icon>{{ sideMenu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ sideMenu.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>
    <!-- ヘッダー -->
    <!-- <v-app-bar color="primary" light app clipped-left>-->
    <v-app-bar color="#C9ECFF" light app clipped-left>
      <!-- ハンバーガーアイコン -->
      <v-app-bar-nav-icon v-if="isShowMenu" @click="drawer = !drawer" style="cursor: pointer"></v-app-bar-nav-icon>
      <!-- サイトタイトル -->
      <v-toolbar-title :style="isShowMenu ? 'cursor: pointer' : ''" @click="toHome">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="isShowMenu">
        <!-- ユーザーメニュー -->
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :text="true">{{ userName }}<v-icon>mdi-menu-down</v-icon></v-btn>
          </template>
          <v-list>
            <v-subheader>ユーザーメニュー</v-subheader>
            <v-list-item
              v-for="userMenu in userMenuItems"
              :key="userMenu.name"
              :to="userMenu.link"
              @click="userMenu.click ? handleFunctionCall(userMenu.click) : undefined"
            >
              <v-list-item-icon>
                <v-icon>{{ userMenu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ userMenu.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <!-- フッター -->
    <!-- 
    <v-footer color="primary text-caption" light app>
      Copyright (C) {{ currentYear }} 物流サポート協同組合 All Rights Reserved.
    </v-footer>
    -->
    <v-footer color="#C9ECFF" light app>
      <span class="text-caption">Copyright (C) {{ currentYear }} 物流サポート協同組合 All Rights Reserved.</span>
    </v-footer>
  </div>
</template>
<script>
import { Auth, Cache } from 'aws-amplify';
import { USER_TYPES } from '@/assets/js/const';

export default {
  data() {
    return {
      title: document.title,
      // サイドメニュー開閉の状態 nullにするとデスクトップだとメニューが開き、モバイルだとメニューが閉じた状態で表示
      drawer: null,
      // ユーザーメニュー
      userMenus: [
        {
          name: 'ログアウト',
          icon: 'mdi-logout-variant',
          click: 'logOut',
          userTypes: [
            USER_TYPES.UNIONIST,
            USER_TYPES.ADMIN,
            USER_TYPES.UNION,
            USER_TYPES.SALES,
            USER_TYPES.MAIL_SUBCONTRACTOR,
          ],
        },
        {
          name: '組合員情報',
          icon: 'mdi-card-account-details-outline',
          click: 'showUnionists',
          userTypes: [USER_TYPES.UNIONIST],
        },
      ],
      // サイドメニュー
      sideMenus: [
        {
          name: '請求書/利用明細一覧',
          icon: 'mdi-alarm-panel-outline',
          active: false,
          link: '/invoice_list',
          userTypes: [USER_TYPES.UNIONIST],
        },
        {
          name: 'ユーザー管理',
          icon: 'mdi-account-edit',
          active: false,
          link: '/user/search',
          userTypes: [USER_TYPES.ADMIN, USER_TYPES.UNION],
        },
        {
          name: '組合員管理',
          icon: 'mdi-domain',
          active: false,
          link: '/unionist/search',
          //koiwa          userTypes: [USER_TYPES.ADMIN, USER_TYPES.UNION],
          userTypes: [USER_TYPES.ADMIN, USER_TYPES.UNION, USER_TYPES.SALES],
        },
        {
          name: '請求書/利用明細一覧（管理）',
          icon: 'mdi-alarm-panel-outline',
          active: false,
          link: '/invoice_list_manage',
          userTypes: [USER_TYPES.ADMIN, USER_TYPES.UNION, USER_TYPES.SALES],
        },
        {
          name: '請求書/利用明細アップロード',
          icon: 'mdi-file-upload',
          active: false,
          link: '/invoice_upload',
          userTypes: [USER_TYPES.ADMIN, USER_TYPES.UNION],
        },
        {
          name: 'コンテンツ管理',
          icon: 'mdi-cog-outline',
          active: false,
          link: '/contents',
          userTypes: [USER_TYPES.ADMIN, USER_TYPES.UNION],
        },
        {
          name: '業務委託手数料アップロード',
          icon: 'mdi-arrow-up-bold-box',
          active: false,
          link: '/commission_list_upload',
          userTypes: [USER_TYPES.ADMIN],
        },
        {
          name: '業務委託手数料一覧',
          icon: 'mdi-view-list',
          active: false,
          link: '/commission_list',
          userTypes: [USER_TYPES.SALES],
        },
        {
          name: '業務委託手数料一覧（管理）',
          icon: 'mdi-view-list',
          active: false,
          link: '/commission_list_manage',
          userTypes: [USER_TYPES.ADMIN],
        },
        {
          name: '郵送委託先様向けダウンロード',
          icon: 'mdi-arrow-down-bold-circle',
          active: false,
          link: '/mail_outsourcer_download',
          userTypes: [USER_TYPES.MAIL_SUBCONTRACTOR],
        },
        {
          name: 'リンク集',
          icon: 'mdi-link-variant',
          active: false,
          link: '/brsupport_link',
          userTypes: [
            USER_TYPES.UNIONIST,
            USER_TYPES.ADMIN,
            USER_TYPES.UNION,
            USER_TYPES.SALES,
            USER_TYPES.MAIL_SUBCONTRACTOR,
          ],
        },
        {
          name: '各種届出様式',
          icon: 'mdi-link-variant',
          active: false,
          link: '/brsupport_format',
          userTypes: [
            USER_TYPES.UNIONIST,
            USER_TYPES.ADMIN,
            USER_TYPES.UNION,
            USER_TYPES.SALES,
            USER_TYPES.MAIL_SUBCONTRACTOR,
          ],
        },
        {
          name: 'マニュアル',
          icon: 'mdi-lightbulb-outline',
          active: false,
          link: '/manual/admin_union_manual',
          userTypes: [USER_TYPES.ADMIN, USER_TYPES.UNION],
        },
        {
          name: 'マニュアル',
          icon: 'mdi-lightbulb-outline',
          active: false,
          link: '/manual/unionist_manual',
          userTypes: [USER_TYPES.UNIONIST],
        },
        {
          name: 'マニュアル',
          icon: 'mdi-lightbulb-outline',
          active: false,
          link: '/manual/sales_manual',
          userTypes: [USER_TYPES.SALES],
        },
        {
          name: 'マニュアル',
          icon: 'mdi-lightbulb-outline',
          active: false,
          link: '/manual/mail_subcontractor_manual',
          userTypes: [USER_TYPES.MAIL_SUBCONTRACTOR],
        },
      ],
    };
  },
  computed: {
    /**
     * ユーザーがログイン済みかどうかを返します。
     * @return {Boolean} ログイン済みの場合はtrue、そうでない場合はfalse
     */
    isShowMenu() {
      return this.$store.getters.$_user ? true : false;
    },
    /**
     * 現在の西暦を返します。
     * @return {Number} 西暦
     */
    currentYear() {
      const jtc = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' });
      return new Date(jtc).getFullYear();
    },
    /**
     * ユーザーの名前を返します。
     * @return {String} ユーザーの氏名
     */
    userName() {
      return this.$store.getters.$_user?.Name ?? '';
    },
    /**
     * ユーザーのタイプでアクセス可能なサイドメニュー（メインメニュー）のリストを返します。
     * @return {Array} ユーザーのタイプでアクセス可能なサイドメニュー（メインメニュー）のリスト
     */
    sideMenuItems() {
      const type = this.$store.getters.$_user.Type;
      return this.sideMenus.filter((item) => item.userTypes.includes(type));
    },
    /**
     * ユーザーのタイプでアクセス可能なユーザーメニューのリストを返します。
     * @return {Array} ユーザーのタイプでアクセス可能なユーザーメニューのリスト
     */
    userMenuItems() {
      const type = this.$store.getters.$_user.Type;
      return this.userMenus.filter((item) => item.userTypes.includes(type));
    },
  },
  methods: {
    /**
     * /homeにいる状態で/homeへ移動するとNavigationDuplicatedが発生するのを回避します。
     */
    toHome() {
      if (this.isShowMenu && this.$route.path !== '/home') {
        this.$router.push('/home');
      }
    },
    /**
     * 指定された関数名の関数を呼び出すヘルパー関数
     */
    handleFunctionCall(functionName) {
      this[functionName]();
    },
    /**
     * ユーザーメニュー：ログアウトクリック処理
     */
    logOut() {
      this.signOut();
    },
    /**
     * ログアウトします。
     */
    async signOut() {
      try {
        await Auth.signOut();
        // Vuexクリア
        this.$store.commit('setStateUser', null);
        // キャッシュクリア
        Cache.clear();
        this.$router.push('/login');
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * ユーザーメニュー：組合員情報クリック処理
     * 組合員情報画面へ遷移します。
     */
    showUnionists() {
      if (this.$route.path != '/unionist') {
        this.$router.push('/unionist');
      }
    },
  },
};
</script>
